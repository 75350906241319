import { twMerge } from 'tailwind-merge';

import { MAIN_CONTENT_ID } from '../../constants';
import NavBar from '../NavBar';

import type { PropsWithChildren } from 'react';

interface MainLayoutProps {
  children: PropsWithChildren<React.ReactNode>;
  className?: string;
  actions?: PropsWithChildren<React.ReactNode>;
}

export const MainLayout = ({
  children,
  className,
  actions,
}: MainLayoutProps) => {
  return (
    <div
      className={twMerge('w-full min-h-screen', className)}
      data-testid="main-layout"
    >
      <main
        id={MAIN_CONTENT_ID}
        className="mx-auto max-w-3xl p-4 md:p-8 bg-gray-100 md:rounded-2xl"
      >
        <NavBar />
        {children}

        {actions && (
          <div className="fixed z-40 py-2 px-4 w-full bottom-0 left-0 right-0 bg-gray-100">
            <div className="max-w-3xl mx-auto">{actions}</div>
          </div>
        )}
      </main>
    </div>
  );
};

export default MainLayout;
