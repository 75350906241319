import type { ErrorType, GraphQLError } from '~/types/errors';

export const fieldErrorMessage = (errors: ErrorType[], field: string) => {
  return errors?.find((error: ErrorType) => error.field === field);
};

export const baseErrorMessages = (errors: ErrorType[]) => {
  if (!errors) {
    return [];
  }
  return errors.filter((error: ErrorType) => error.field === 'base');
};

export const parseBaseErrorsFromResponse = (errs: GraphQLError[]) => {
  let errors = errs.map((graphErr) => ({
    field: 'base',
    message: graphErr.message,
    code: graphErr.extensions?.code,
  }));
  errors = errors.filter((err) => err.code === 'USER_ERROR');
  return { errors };
};
