import { ExtendedTwirl } from './ExtendedTwirlSVG';

export const NavBar = () => {
  return (
    <div className="mx-auto max-w-3xl">
      <nav className="flex justify-between items-center px-4 py-2 min-h-[56px]">
        <ExtendedTwirl />
      </nav>
    </div>
  );
};
